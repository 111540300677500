<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.new_m", {
                m: $t("message.dealGoal"),
              })
            }}</p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <div>
                  <el-row :gutter="20" class="mb-5 pb-5">
                    <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                       <el-form-item
                          :label="$t('message.year')"
                          class="label_mini mb-0"
                          prop="year"
                        >
                          <div>
                            <el-select size="medium" v-model="form.year" :value="form.year" :placeholder="$t('message.year')" clearable >
                              <el-option
                                v-for="item in options"
                                :key="item"
                                :label="item"
                                :value="item">
                              </el-option>
                            </el-select>
                          </div>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" v-if="form.year">
                        <el-form-item
                          :label="$t('message.filial')"
                          class="label_mini mb-0"
                          prop="filial_id"
                        >
                          <select-filial
                            :size="'medium'"
                            :placeholder="$t('message.filial')"
                            :id="form.filial_id"
                            v-model="form.filial_id"
                          >
                          </select-filial>
                        </el-form-item>
                    </el-col>
                    
                    <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" v-if="form.year">
                        <el-form-item
                          :label="$t('message.currency')"
                          class="label_mini mb-0"
                          prop="currency_id"
                        >
                          <select-currency
                            :size="'medium'"
                            :placeholder="$t('message.currency')"
                            :id="form.currency_id"
                            v-model="form.currency_id"
                          >
                          </select-currency>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" v-if="form.year">
                      <el-form-item
                        :label="$t('message.role')"
                        class="label_mini mb-0"
                        prop="role_id"
                      >
                        <select-role
                          :id="form.role_id"
                          v-model="form.role_id"
                          :size="'medium'"
                          :placeholder="columns.role_id.title"
                        ></select-role>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              
                <el-row :gutter="20" class="mb-3" v-if="form.year">
                    <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" class="color-o">
                      {{ $t('message.month') }}:
                    </el-col>
                    <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5"  class="color-o">
                      {{ $t('message.count') }}:
                      <el-form-item
                      prop="count"
                      >
                        <el-input
                            :placeholder="$t('message.count')"
                            type="number"
                            :value="0"
                            v-model="totals.count"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5"  class="color-o">
                        {{ $t('message.money_amount') }}:
                        <el-form-item
                        prop="money_amount"
                        >
                          <el-input
                              type="number"
                              :placeholder="$t('message.money_amount')"
                              v-model="totals.money_amount"
                              size="medium"
                          ></el-input>
                        </el-form-item>  
                    </el-col>
                    <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5"  class="color-o">  
                        {{ $t('message.profit') }}:
                        <el-form-item
                        prop="profit"
                        >
                          <el-input
                              type="number"
                              :placeholder="$t('message.profit')"
                              v-model="totals.profit"
                              size="medium"
                          ></el-input>
                        </el-form-item> 
                    </el-col>
                    <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5"  class="color-o">
                      {{ $t('message.meetings') }}:
                      <el-form-item
                      prop="meetings"
                      >
                        <el-input
                            :placeholder="$t('message.meetings')"
                            type="number"
                            :value="0"
                            v-model="totals.meetings"
                            size="medium"
                        ></el-input>
                      </el-form-item>   
                    </el-col>
                </el-row>
                <hr v-if="form.year">
                <div v-if="form.year">
                  <el-row :gutter="20" v-for="month in months" :key="month.id">
                      <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
                        <span class="color-o"> {{ month.month }} </span>
                      </el-col>
                      <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
                        <el-form-item
                        prop="count"
                        >
                          <el-input
                              :placeholder="$t('message.count')"
                              type="number"
                              :value="0"
                              v-model="month.count"
                              size="medium"
                          ></el-input>
                        </el-form-item>     
                      </el-col>

                      <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
                        <el-form-item
                        prop="money_amount"
                        >
                          <el-input
                              type="number"
                              :placeholder="$t('message.money_amount')"
                              v-model="month.money_amount"
                              size="medium"
                          ></el-input>
                        </el-form-item>     
                      </el-col>

                      <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
                        <el-form-item
                        prop="profit"
                        >
                          <el-input
                              type="number"
                              :placeholder="$t('message.profit')"
                              v-model="month.profit"
                              size="medium"
                          ></el-input>
                        </el-form-item>     
                      </el-col>

                      <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
                        <el-form-item
                        prop="meetings"
                        >
                          <el-input
                              :placeholder="$t('message.meetings')"
                              type="number"
                              :value="0"
                              v-model="month.meetings"
                              size="medium"
                          ></el-input>
                        </el-form-item>     
                      </el-col>
                  </el-row>
                </div>

            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectCurrency from "@/components/inventory/select-currency";
import selectFilial from "@/components/inventory/select-filial";
import selectRole from "@/components/inventory/select-role";

export default {
  
  mixins: [form, drawer],
  components: {
    selectCurrency,
    selectFilial,
    selectRole
  },
  data() {
    return {
      totals: {
        months: '',
        count: 0,
        money_amount: 0,
        profit: 0,
        meetings: 0
      },
      months: [
        {month: this.$t('message.January')}, 
        {month: this.$t('message.February')}, 
        {month: this.$t('message.March')}, 
        {month: this.$t('message.April')}, 
        {month: this.$t('message.May')}, 
        {month: this.$t('message.June')}, 
        {month: this.$t('message.July')}, 
        {month: this.$t('message.August')}, 
        {month: this.$t('message.September')}, 
        {month: this.$t('message.October')}, 
        {month: this.$t('message.November')}, 
        {month: this.$t('message.December')}
      ],  
      year: null,
      options: [],
    };
  },
  created() {
    for (let i = 2000; i < 2100; i++) {
      this.options.push(i);
    }
  },
  watch: {
    watchAll: {
        handler: async function(newVal, oldVal) {
          if(this.form.filial_id){
            this.updateGoalYears({year: this.form.year, filial_id: this.form.filial_id, role_id: this.form.role_id,})
              .then(res => {
                  if(this.selectedYears.exists){
                    this.$message({
                      type: "warning",
                      message: this.selectedYears.message
                    });
                  }else{
                    console.log('deal goal');
                  }
              })
          }
        },
        deep: true,
        immediate: true
    },
    totals: {
        handler: async function(newVal, oldVal) {
          if(newVal){
            this.months.forEach(element => {
              this.$set(element, 'count', newVal.count);
              this.$set(element, 'money_amount', newVal.money_amount);
              this.$set(element, 'profit', newVal.profit);
              this.$set(element, 'meetings', newVal.meetings);
            });
          }
        },
        deep: true,
        immediate: true
    },
  },

  computed: {
      ...mapGetters({
          rules: "dealGoals/rules",
          model: "dealGoals/model",
          columns: "dealGoals/columns",
          selectedYears: "dealGoals/years"
      }),
      watchAll(){
        return this.form.year + this.form.filial_id + this.form.role_id + 'aa'
      }
  },
  methods: {
      ...mapActions({
          save: "dealGoals/store",
          updateGoalYears: "dealGoals/selectedGoalYears",
      }),
      submit(close = true) {
        if(!this.selectedYears.exists){
          this.form.months = this.months; 
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
        }else{
          this.$message({
            type: "warning",
            message: this.selectedYears.message
          });
        }
      },
      afterLeave() {
        this.form = {};
      }
  },
};
</script>
